<script setup lang="ts">
import { FavoriteEdit, QrScan, ToolButton } from '@/components';
import { useFabricStore } from '@/store/fabric.ts';
import { useRouter } from 'vue-router';
import { useParamStore } from '@/store/param.ts';
import { $t } from '@/locales';

const fabricStore = useFabricStore();

const cloudKnitFabricReg = /^(?:https?:\/\/)?(?:[\w-]+\.)?cloudknit\.cn\/fabric\/([\w-]+)$/;

const validator = (text: string) => {
  return cloudKnitFabricReg.test(text);
};

const { refreshLoadResourceByRoute } = useParamStore();

const router = useRouter();
const onScanSuccess = async (val: string) => {
  const match = val.match(cloudKnitFabricReg);
  if (match && match[1]) {
    await router.push({ name: 'detail', params: { id: match[1] } });
    await refreshLoadResourceByRoute();
  }
};
</script>

<template>
  <FavoriteEdit :fabric="fabricStore.fabricInfo">
    <ToolButton>
      {{ $t('common.favorite') }}
    </ToolButton>
  </FavoriteEdit>
  <QrScan :validator="validator" :callback="onScanSuccess">
    <template #scan-icon="slotProps">
      <ToolButton :loading="slotProps.loading">
        {{ $t('common.scan') }}
      </ToolButton>
    </template>
  </QrScan>
</template>

<style module>
</style>
