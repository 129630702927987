import type { RouteRecordRaw } from 'vue-router';
import { FabricPage } from '@/views/fabric';
import { FavoritePage } from '@/views/favorite';
import { TryOnPage } from '@/views/tryon';
import { MyTryOnPage } from '@/views/mytryon';
import { BasicLayout } from '@/layouts';
import { DetailPage } from '@/views/detail';

export const routes: RouteRecordRaw[] = [
  {
    component: BasicLayout,
    name: 'home',
    path: '/',
    redirect: '/fabric/default',
    children: [
      {
        name: 'project',
        path: '/:id',
        alias: '/project/:id',
        component: FabricPage
      },
      {
        name: 'fabric',
        path: '/fabric/:id',
        component: FabricPage
      },
      {
        name: 'style3d',
        path: '/style3d/:id',
        component: FabricPage
      },
      {
        name: 'favorite',
        path: '/favorite',
        component: FavoritePage
      },
      {
        name: 'tryon',
        path: '/tryon',
        component: TryOnPage
      },
      {
        name: 'mytryon',
        path: '/mytryon',
        component: MyTryOnPage
      },
      {
        name: 'detail',
        path: '/detail/:id',
        component: DetailPage
      }
    ]
  }
];
