<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import {
  Checkbox as VanCheckbox,
  Icon as VanIcon,
  Image as VanImage,
  Uploader as VanUploader,
  showNotify,
  showToast
} from 'vant';
import { createTryonTask, saveTryon, uploadFileViaStsToken } from '@/api';
import { Toolbar, ModelList } from './components';
import { useAddressBook } from '@/components';
import { $t } from '@/locales';
import { modelImageList, pantsImageList } from './models.ts';
import type { UploadFileInfo } from '@/views/tryon/typing.ts';

const route = useRoute();
const clothImage = ref(route.query.cloth as string);
const enterpriseId = ref(route.query.enterpriseId as string);
const fabricId = ref(route.query.fabricId as string);

const pantsImage = ref('');


const modelImage = ref<UploadFileInfo>();
const showPants = ref(false);
const router = useRouter();

const { getAddressBook, toggleModal: toggleAddressModal } = useAddressBook();

onMounted(() => {
  const id = localStorage.getItem('lastTryonModelImage');
  if (id) {
    modelImage.value = modelImageList.value.find((item) => item.id === id);
  }
});

const onClickTryon = async () => {
  if (!modelImage.value) {
    showNotify({ type: 'warning', message: $t('tryon.message.select-model') });
  } else {
    localStorage.setItem('lastTryonModelImage', modelImage.value.id);

    const addressBook = getAddressBook();
    if (!addressBook) {
      showToast($t('tryon.message.contact-first'));
      setTimeout(() => {
        toggleAddressModal(true);
      }, 1000);
      return;
    }

    try {
      const task = await createTryonTask({
        phone: addressBook.phone,
        fabricid: fabricId.value,
        enterpriseid: enterpriseId.value,
        model: 'aitryon',
        input: {
          top_garment_url: clothImage.value,
          bottom_garment_url: pantsImage.value,
          person_image_url: modelImage.value.url
        }
      });

      if (task.status === 'SUCCEED') {
        await saveTryon({
          id: 0,
          clothUrl: clothImage.value,
          modelUrl: modelImage.value.url,
          task_status: task.output.task_status,
          task_id: task.output.task_id,
          request_id: task.request_id,
          pantsUrl: pantsImage.value
        });

        await router.push({
          path: '/mytryon',
          state: {
            params: {
              cloth: modelImage.value.url,
              pants: showPants.value ? pantsImage.value : null
            }
          }
        });
      } else {
        showNotify({ type: 'danger', message: task.message });
      }
    } catch (e: any) {
      showNotify({ type: 'danger', message: e.message });
    }
  }
};

const onClickMyTryon = async () => {
  await router.push(`/mytryon`);
};

const afterRead = async (item: any) => {
  const file: File = item.file;
  const key = crypto.randomUUID().replaceAll('-', '');
  const date = new Date();

  try {
    const path = `/aitryon/${date.getFullYear()}${date.getMonth() + 1}${date.getDate()}/${key}/` + file.name;
    const uploadedUrl: string = await uploadFileViaStsToken(path, file);
    if (uploadedUrl) {
      clothImage.value = uploadedUrl;
    }
  } catch (ex) {
    console.error(ex);
  }
};

</script>

<template>
  <div :class="$style.tryonPage">
    <div :class="$style.container">
      <h4 :class="$style.subtitle">{{ $t('tryon.model-pic') }}</h4>

      <ModelList :class="$style.modelList" :imageList="modelImageList" v-model="modelImage" />

      <h4 :class="$style.subtitle">{{ $t('tryon.upper') }}</h4>

      <div :class="$style.content">
        <van-image lazy-load width="100%" height="300" fit="contain" :src="clothImage" />
        <van-uploader :class="$style.modelUploader" :after-read="afterRead">
          <div :class="$style.iconContainer">
            <van-icon name="photograph" size="22"></van-icon>
          </div>
        </van-uploader>
      </div>

      <h4 :class="$style.subtitle">
        {{ $t('tryon.lower') }}
        <van-checkbox v-model="showPants" shape="square" style="margin-left: 5px;"></van-checkbox>
      </h4>

      <ModelList :class="$style.modelList" :imageList="pantsImageList" v-model="pantsImage" v-if="showPants" />

    </div>
    <Toolbar :class="$style.tabbar" @tryon="onClickTryon" @mytryon="onClickMyTryon" />
  </div>

</template>

<style module>

.tryonPage {
  position: relative;
  height: 100%;
  overflow: hidden;
  padding-bottom: 40px;
}

.container {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.content {
  width: 100%;
  overflow: hidden;
  padding: 0 10px;
  position: relative;
}

.modelList {
  padding: 0 10px 0;
  display: flex;
  align-items: center;
}

.modelUploader {
  position: absolute;
  border-radius: 5px;
  top: 10px;
  right: 10px;
}

.iconContainer {
  background: rgb(247, 248, 250);
  width: 28px;
  height: 28px;
  border-radius: 5px;
  padding-top: 3px;
  padding-left: 3px;
}

.subtitle {
  display: flex;
  margin-left: 10px;
  margin-top: 5px;
}

.tabbar {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  padding-bottom: 10px;
  background-color: #ffffff;
}

</style>
