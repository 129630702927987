<script setup lang="ts">
import { useAddressBookProvider } from './useAddressBook.ts';
import AddressBook from './address-book.vue';

const { showModal, addressBook, save } = useAddressBookProvider();
</script>

<template>
  <teleport to="body">
    <AddressBook
      v-model:show-modal="showModal"
      v-model:address-book="addressBook"
      @submit="save"
    />
  </teleport>
  <slot></slot>
  <div>

  </div>
</template>

<style scoped>

</style>
