<script setup lang="ts">
import { Toolbar, FabricCard } from './components';
import { Search, DropdownMenu, DropdownItem, showToast } from 'vant';
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import {
  deleteFavorites,
  type Favorite,
  getEnterprises,
  getFavoriteByID,
  getFavorites,
  saveFavorite,
  searchFavorites
} from '@/api';
import type { FavoriteItem } from './typing.ts';
import { dateRange } from '@repo/utils';
import { ToolbarContainer } from '@/components';
import { getFabricShareInfo } from '@/utils';
import { $t } from '@/locales';

const favorites = ref<FavoriteItem[]>([]);

onMounted(async () => {
  await queryFavorites();
  await getEnterpriseOptions();
});

const date = ref(getDefaultDateOption());
const dateOptions = ref([
  { text: $t('favorite-page.today'), value: 0 },
  { text: $t('favorite-page.this-week'), value: 1 },
  { text: $t('favorite-page.this-month'), value: 2 },
  { text: $t('favorite-page.all-time'), value: -1 }
]);

const enterprise = ref<string>('');
const enterpriseOptions = ref([{ text: $t('favorite-page.all-company'), value: '' }]);

function getDefaultDateOption() {
  return parseInt(localStorage.getItem('favorite-page-date-option') || '-1');
}

async function getEnterpriseOptions() {
  const enterprises = await getEnterprises();
  enterpriseOptions.value = [...enterprises.map(item => ({ text: item.name, value: item.id })), {
    text: $t('favorite-page.all-company'),
    value: ''
  }];
}

watch(() => date.value, (v) => {
  localStorage.setItem('favorite-page-date-option', v.toString());
});

const range = computed<[number, number] | undefined>(() => {
  switch (date.value) {
    case 0:
      return dateRange('day');
    case 1:
      return dateRange('week');
    case 2:
      return dateRange('month');
  }
  return undefined;
});

const searchTerms = ref<string>('');
const queryFavorites = async () => {
  let items: Favorite[] = [];
  if (searchTerms.value) {
    items = await searchFavorites(searchTerms.value, 9999, enterprise.value, range.value);
  } else {
    items = await getFavorites(9999, enterprise.value, range.value);
  }
  favorites.value = items;
};

const onSearch = async () => {
  await queryFavorites();
};

const onSelected = (item: FavoriteItem, val: boolean) => {
  item.selected = val;
};

const onSelectAll = () => {
  favorites.value.forEach((item) => {
    item.selected = true;
  });
};

const selected = computed(() => {
  return favorites.value.filter(item => item.selected);
});

const onChange = (payload: { id: string, memo: string, num: number }) => {
  const { id, memo, num } = payload;
  const found = favorites.value.find(item => item.id === id);
  if (found) {
    found.memo = memo;
    found.num = num;
  }
};

const onDelete = async () => {
  if (selected.value.length < 1) {
    return;
  }
  const ids = selected.value.map(item => item.id);
  await deleteFavorites(ids);
  await queryFavorites();
};

const onScan = async (id: string) => {
  const info = await getFabricShareInfo(id);
  const data = { ...info, memo: '', num: 1 };
  await saveFavorite(data);
  favorites.value = [data];
  await nextTick(() => {
    showToast($t('message.favorite-success'));
  });
};
</script>

<template>
  <div :class="$style.favoritePage">
    <div :class="$style.header">
      <Search
        :class="$style.headerRow"
        v-model="searchTerms"
        :placeholder="$t('favorite-page.search-placeholder')"
        @search="onSearch"
        show-action
      >
        <template #action>
          <div @click="onSearch">{{ $t('common.search') }}</div>
        </template>
      </Search>
      <DropdownMenu :class="$style.headerRow">
        <DropdownItem v-model="date" :options="dateOptions" @change="onSearch" />
        <DropdownItem v-model="enterprise" :options="enterpriseOptions" @change="onSearch" />
      </DropdownMenu>
    </div>
    <div :class="$style.content">
      <div :class="$style.favoriteList">
        <FabricCard
          :class="$style.item"
          v-bind="item" v-for="item in favorites"
          :key="item.id"
          @selected="(val)=>onSelected(item,val)"
          @change="onChange"
        />
      </div>
    </div>
    <ToolbarContainer :dark="false">
      <Toolbar
        @select-all="onSelectAll"
        @delete="onDelete"
        @scan="onScan"
        :enterprise-id="enterprise"
        :selected="selected"
        :favoriteList="favorites"
      />
    </ToolbarContainer>
  </div>
</template>

<style module>
.favoritePage {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.header {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 0;
}

.headerRow {
  width: 100%;
}

.content {
  flex: 1;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-top: solid 1px #d0d0d0;
}

.favoriteList {
  flex: 1;
  width: 100%;
  padding: 10px;
  overflow-y: auto;
}

.favoriteList > .item {
  margin-bottom: 10px;
}
</style>

<style scoped>
:deep(.van-dropdown-menu__bar) {
  box-shadow: unset;
}
</style>
