<script setup lang="ts">
import { FavoriteEdit, PopoverButtons, QrScan, ToolButton } from '@/components';
import { ResolutionToggle } from '.';
import { useFabricStore } from '@/store/fabric.ts';
import { useRouter } from 'vue-router';
import { getAppConfig } from '@/utils';
import { useParamStore } from '@/store/param.ts';
import axios from 'axios';
import { base64ToFile, getImageSize, isWeixinBrowser, joinUrl } from '@repo/utils';
import { showToast } from 'vant';
import { useStyle3d } from '@repo/style3d';
import { useSelectionStore } from '@repo/stores';
import { uploadFileViaStsToken } from '@/api';
import { $t } from '@/locales';

const fabricStore = useFabricStore();
const router = useRouter();

const style3d = useStyle3d();
const paramStore = useParamStore();
const onClickTryon = async () => {
  if (paramStore.scoId === 'fabric_hanger') {
    showToast($t('message.select-sco'));
    return;
  }
  const data = await style3d.getScreenshot();
  const enterpriseId = fabricStore?.fabricInfo?.enterpriseId || '';
  const fabricId = fabricStore?.fabricInfo?.id || '';

  const file = base64ToFile(data, 'screenshot.png');
  const key = crypto.randomUUID().replaceAll('-', '');
  const date = new Date();
  const path = `/aitryon/${date.getFullYear()}${date.getMonth() + 1}${date.getDate()}/${key}/screenshot.png`;

  const uploadedUrl = await uploadFileViaStsToken(path, file);
  await router.push({
    name: 'tryon',
    query: {
      cloth: uploadedUrl,
      enterpriseId,
      fabricId
    }
  });
};

const cloudKnitReg = /^(?:https?:\/\/)?(?:[\w-]+\.)?cloudknit\.cn(?:\/project)?\/(?:([^/?]+)\/)?([^/?]+)$/;
const oldCloudKnitReg = /^(?:https?:\/\/)?([\w-]+)\.?cloudknit\.cn\/\?pathId=([a-zA-Z0-9]+)/;
const style3dReg = /(?:https?:\/\/)?(?:[\w-]+\.)?style3d\.com\/fabric\/(\w+)/;
const style3dShortReg = /(?:https?:\/\/)?(?:[\w-]+\.)?style3d\.com\/u\/(\w+)/;
const validator = (text: string) => {
  return oldCloudKnitReg.test(text) || cloudKnitReg.test(text) || style3dReg.test(text) || style3dShortReg.test(text);
};

const { URL_PARSE_URL } = getAppConfig();
const { applyFabricToProject, refreshLoadResourceByRoute } = useParamStore();

const onScanSuccess = async (val: string) => {
  if (cloudKnitReg.test(val) || oldCloudKnitReg.test(val)) {
    await handleCloudKnitResource(val);
  } else if (style3dReg.test(val)) {
    await handleStyle3dResource(val);
  } else if (style3dShortReg.test(val)) {
    const match = val.match(style3dShortReg);
    if (match && match[1]) {
      try {
        const response = await axios.get<string>(joinUrl(URL_PARSE_URL, `/style3d/u/${match[1]}`));
        await handleStyle3dResource(response.data);
      } catch (error) {
        console.error('Error fetching the real URL:', error);
      }
    }
  }
};
const handleStyle3dResource = async (url: string) => {
  const match = url.match(style3dReg);
  if (match && match[1]) {
    await router.push({ name: 'style3d', params: { id: match[1] } });
    await refreshLoadResourceByRoute();
  }
};

const handleCloudKnitResource = async (val: string) => {
  let type: string = '';
  let id: string = '';
  const match = val.match(cloudKnitReg) || val.match(oldCloudKnitReg);
  if (match && match[2]) {
    type = match[1] || '';
    id = match[2];
    const name = type === 'fabric' ? 'fabric' : 'project';

    if (name === 'fabric') {
      await router.push({ name, params: { id } });
      await refreshLoadResourceByRoute();
    } else {
      await applyFabricToProject(id);
    }
  }
};

const { changeFabric } = useStyle3d();

const selectionStore = useSelectionStore();

const onChooseImage = () => {
  if (!isWeixinBrowser()) {
    showToast($t('message.not-supported-yet'));
    return;
  }
  // todo 封装为异步函数
  window.wx.chooseImage({
    count: 1,
    sizeType: ['original', 'compressed'],
    sourceType: ['album', 'camera'],
    success: function(res: any) {
      window.wx.getLocalImgData({
        localId: res.localIds[0], // 图片的localID
        success: function(res: any) {
          let localData = res.localData;
          if (localData.indexOf('data:image') !== 0) {
            localData = `data:image/jpg;base64,${localData}`;
          }
          getImageSize(localData)
            .then(imageSize => {
              showToast(JSON.stringify(imageSize));
              changeFabric({
                id: selectionStore.lastSelectedFabricId,
                xhrPath: localData,
                ...imageSize
              });
            });

        },
        fail: function(err: any) {
          showToast(`fail: ${JSON.stringify(err)}`);
        }
      });

    }
  });
};

</script>

<template>
  <PopoverButtons dark placement="right">
    <template #reference>
      <ToolButton>
        {{ $t('common.more') }}
      </ToolButton>
    </template>
    <ResolutionToggle />
    <div v-show="false">
      <ToolButton @click="onChooseImage">
        {{ $t('common.photograph') }}
      </ToolButton>
    </div>
    <div v-show="false">
      <ToolButton>
        {{ $t('common.edit') }}
      </ToolButton>
    </div>
    <div>
      <ToolButton @click="onClickTryon">
        {{ $t('toolbar.outfit') }}
      </ToolButton>
    </div>
  </PopoverButtons>
  <FavoriteEdit :fabric="fabricStore.fabricInfo">
    <ToolButton>
      {{ $t('common.favorite') }}
    </ToolButton>
  </FavoriteEdit>
  <QrScan :validator="validator" :callback="onScanSuccess">
    <template #scan-icon="slotProps">
      <ToolButton :loading="slotProps.loading">
        {{ $t('common.scan') }}
      </ToolButton>
    </template>
  </QrScan>
</template>

<style module>

</style>
