<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { getRecentProjects, type Project } from '@/api';
import { useRecentListStore } from '@/store/recent-list';
import { useParamStore } from '@/store/param.ts';
import { Image } from 'vant';

const projects = ref<Project[]>([]);
const recentListStore = useRecentListStore();

onMounted(async () => {
  projects.value = await getRecentProjects();
});

const { applyFabricToProject } = useParamStore();

const onClick = async (project: Project) => {
  await applyFabricToProject(project.scoId);
};
</script>
<template>
  <div :class="$style.shareListContainer">
    <div
      v-for="(item) in recentListStore.recentProjects"
      :key="item.id"
    >
      <Image
        width="75"
        height="75"
        :src="item.thumb"
        fit="scale-down"
        @click="()=>onClick(item)"
      />
    </div>
  </div>
</template>
<style module>
.shareListContainer {
  padding: 0px 0px 0px;
  display: flex;
  overflow: auto;
}
</style>
