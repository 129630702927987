import { createApp } from 'vue';
import App from '@/App.vue';
import { router } from '@/router';
import { initStores } from '@repo/stores';
import { Lazyload } from 'vant';
import { setupI18n, getLocale } from '@/locales';


async function bootstrap() {
  const app = createApp(App);

  await setupI18n(app, getLocale());

  initStores(app);

  app.use(router);
  app.use(Lazyload);
  app.mount('#app');
}

export { bootstrap };
