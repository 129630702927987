<script setup lang="ts">
import { Tabbar, TabbarItem } from 'vant';
import { computed, ref } from 'vue';
import { useParamStore } from '@/store/param.ts';
import { storeToRefs } from 'pinia';
import { $t } from '@/locales';

const active = ref(0);

const paramStore = useParamStore();
const { fabricId, scoId } = storeToRefs(paramStore);

const extra = computed(() => ({
  params: {
    id: fabricId.value || 'default'
  },
  query: {
    scoId: scoId.value
  }
}));
</script>

<template>
  <Tabbar v-model="active" placeholder route safe-area-inset-bottom>
    <TabbarItem class="tabbar-item" replace :to="{name:'detail',...extra}">
      {{ $t('common.details') }}
    </TabbarItem>
    <TabbarItem class="tabbar-item" replace :to="{name:'fabric',...extra}">
      3D
    </TabbarItem>
    <TabbarItem class="tabbar-item" replace :to="{name:'favorite'}">
      {{ $t('tabbar.favorites') }}
    </TabbarItem>
  </Tabbar>
</template>

<style scoped>
.tabbar-item {
  font-size: 16px;
}
</style>
