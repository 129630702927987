<script setup lang="ts">
import { ref, defineModel, computed } from 'vue';
import { Icon as VanIcon, Swipe as VanSwipe, SwipeItem as VanSwipeItem, Uploader as VanUploader } from 'vant';
import { Image } from '@/components';
import type { UploadFileInfo } from '../typing';
import { uploadFileViaStsToken } from '@/api';

const model = defineModel<UploadFileInfo>();
const props = defineProps<{
  imageList: UploadFileInfo[],
}>();

const userUploadImageList = ref<UploadFileInfo[]>([]);

const selectedImageId = computed(() => model.value?.id);

const onSelectImage = (imageFile: UploadFileInfo) => {
  model.value = imageFile;
};

const clearSelection = () => {
  model.value = undefined;
};

const afterRead = async (item: any) => {
  clearSelection();

  const file: File = item.file;
  const key = crypto.randomUUID().replaceAll('-', '');
  const date = new Date();

  try {
    // 将文件上传至服务器
    const path = `/aitryon/${date.getFullYear()}${date.getMonth() + 1}${date.getDate()}/${key}/` + file.name;
    const uploadedUrl = await uploadFileViaStsToken(path, file);

    const item: UploadFileInfo = {
      id: key,
      name: file.name,
      status: 'finished',
      url: uploadedUrl
    };
    userUploadImageList.value.push(item);
    model.value = item;
  } catch (ex) {
    console.error(ex);
  }
};

</script>

<template>

  <div class="share-list-container">
    <van-swipe
      class="my-swipe"
      slides-per-view="auto"
      :space-between="5"
      :loop="false"
      :show-indicators="false"
      draggable
    >

      <div class="uploader-wrapper">
        <van-uploader :after-read="afterRead" />
      </div>

      <van-swipe-item
        v-for="(item) in [...userUploadImageList, ...props.imageList]"
        :key="item.id"
        class="carousel-item" :class="{'active': item.id == selectedImageId}"
        @click="onSelectImage(item)"
      >
        <Image lazy-load :width="100" :height="160" fit="scale-down" :src="item.url || ''"></Image>
        <van-icon class="carousel-icon" v-if="item.id == selectedImageId" name="success" color="white" />
      </van-swipe-item>

    </van-swipe>

  </div>

</template>

<style scoped>

.share-list-container {
  padding: 0px 5px;
}

.uploader-wrapper {
  margin-right: 2px;
  display: flex;
  align-items: center;
}

.uploader-wrapper .van-uploader {
  margin-top: 20px;
  margin-left: 4px;
}

.carousel-item {
  width: 100px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(1);
  box-sizing: border-box;
}

.my-swipe .van-swipe-item {
  margin-right: 4px;
}

.carousel-item.active {
  /* transform: scale(1.2); */
  border: 1px solid var(--van-checkbox-checked-icon-color);
}

.carousel-img {
  height: 160px;
  width: 100px;
  object-fit: scale-down;
}

.carousel-icon {
  background-color: var(--van-checkbox-checked-icon-color);
  position: absolute;
  top: 0;
  right: 0;
}
</style>
