<script setup lang="ts">
import { computed } from 'vue';
import { QRScanModal } from '@repo/components';
import { isWeixinBrowser } from '@repo/utils';
import { until } from '@vueuse/core';
import { useWx } from '@repo/wx';
import { showNotify } from 'vant';
import { $t } from '@/locales';

const props = defineProps<{
  validator: (text: string) => boolean;
  callback: (text: string) => void;
}>();

const isWechat = isWeixinBrowser();

const { wxScan, wxInstance } = useWx();

const loading = computed(() => !wxInstance.isReady.value);

const onScan = async () => {
  if (loading.value) {
    return;
  }

  try {
    await until(wxInstance.isReady).toBe(true);

    await wxScan({
      onSuccess: function(res: any) {
        const valid = props.validator(res.resultStr);
        if (valid) {
          props.callback(res.resultStr);
        } else {
          showNotify({ type: 'warning', message: $t('message.verify-fail') });
        }
      },
      onFailure: function(err: any) {
        showNotify({ type: 'warning', message: JSON.stringify(err) });
      }
    });
  } catch (e) {
    showNotify({ type: 'warning', message: JSON.stringify(e) });
  }
};
</script>

<template>
  <div>
    <span v-if="isWechat" @click="onScan" :style="{  color: wxInstance.isReady ? '#d0d0d0' : '#6c6c6c' }">
       <slot name="scan-icon" :loading="loading"></slot>
    </span>
    <template v-else>
      <QRScanModal @change="props.callback" :validator="validator">
        <slot name="scan-icon" :loading="false">
        </slot>
      </QRScanModal>
    </template>
  </div>
</template>
<style scoped>
</style>
