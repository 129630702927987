import { getAppConfig } from '@/utils/env.ts';
import { joinUrl } from '@repo/utils';
import axios, { type AxiosResponse } from 'axios';
import type { FabricShareInfo } from '@repo/style3d';
import type { FabricInfo } from '@/store/fabric.ts';

const { OSS_BASE_URL, INDIVIDUAL_FABRIC_SHARES } = getAppConfig();
const fabricRootPath = `${OSS_BASE_URL}${INDIVIDUAL_FABRIC_SHARES}`;

export async function getFabricShareInfo(fabricId: string): Promise<FabricInfo> {
  const fabricRoot = joinUrl(fabricRootPath, fabricId);
  const infoResult: AxiosResponse<FabricShareInfo> = await axios.get(joinUrl(fabricRoot, 'info.json'));
  const info = infoResult.data;

  return {
    id: fabricId,
    info: info.metaData.info,
    enterpriseId: info.metaData.enterpriseID,
    enterpriseName: info.metaData.enterpriseName,
    thumb: joinUrl(fabricRoot, 'fabric', info.info.main.name),
    properties: info.metaData.property,
    styleProperties: info.metaData?.propertyStyle,
    carousels: info.doc?.map(item => ({ ...item, url: joinUrl(fabricRoot, item.name) })) || []
  };
}
