<script setup lang="ts">
import { Button, Dialog, Field, Form } from 'vant';
import type { AddressBook } from './useAddressBook.ts';
import { $t } from '@/locales';

const showModal = defineModel<boolean>('showModal');
const addressBook = defineModel<AddressBook>('addressBook', { required: true });

const emit = defineEmits<{
  submit: []
}>();

const onSubmit = () => {
  showModal.value = false;

  emit('submit');
};
</script>

<template>

  <teleport to="body">
    <Dialog v-model:show="showModal" :show-confirm-button="false" close-on-click-overlay>
      <div :class="$style.dialogContent">
        <Form>
          <Field
            v-model="addressBook.company"
            name="company"
            :label="$t('address-book.company')"
            :placeholder="$t('address-book.company')"
          />
          <Field
            v-model="addressBook.contact"
            name="contact"
            :label="$t('address-book.contact')"
            :placeholder="$t('address-book.contact')"
          />
          <Field
            v-model="addressBook.phone"
            name="phone"
            type="tel"
            :label="$t('address-book.phone')"
            :placeholder="$t('address-book.phone')"
            :rules="[{ required: true, message: $t('address-book.phone') }]"
          />
          <Field
            v-model="addressBook.address"
            name="address"
            :label="$t('address-book.address')"
            :placeholder="$t('address-book.address')"
          />
          <Field
            v-model="addressBook.memo"
            name="memo"
            type="textarea"
            :label="$t('address-book.memo')"
            :placeholder="$t('address-book.memo')"
          />
          <div style="display: flex; justify-content: flex-end; column-gap: 10px;">
            <Button type="primary" size="small" native-type="submit" @click="onSubmit">
              {{ $t('common.confirm') }}
            </Button>
            <Button size="small" @click="showModal=false">
              {{ $t('common.cancel') }}
            </Button>
          </div>
        </Form>
      </div>
    </Dialog>
  </teleport>
  <slot></slot>
  <div>

  </div>
</template>

<style module>
.dialogContent {
  padding: 5px;
}
</style>
